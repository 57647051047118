<template>
  <div class="container ma-tb-20">
  	<car-state></car-state>
		<div class="bg-fff setting-index">
			<back-button msg="系统参数"></back-button>
			<div class="setting-parameter">
				<div class="fon-18">
					数据接口地址:
				</div>
				<van-field class="ma-t-10" v-model="url" placeholder="请输入用户名" placeholder-class="fon-16" />
			</div>
			<div class="setting-parameter">
				<div class="fon-18">
					账号登录密码:
				</div>
				<van-field class="ma-t-10" v-model="password" placeholder="为保证安全,请输入当前登录账号密码" placeholder-class="fon-16" />
			</div>
			
			<div class="flex f-center f-jcenter submit-btn c-fff fon-16" @click="submit">
				确认保存
			</div>
		</div>
  </div>
</template>
<script>
let _this;
export default {
  data () {
    return {
			url: '',
			password: ''
    }
  },
  created(){
    _this = this;
		_this.loadData();
  },
  methods:{
		// 加载数据
		async loadData(){
			let _data = await _this.$request('/Ambulance/v1/GetApi');
			if (_data.Code == 0) {
				_this.url = _data.Data.Url;
			}
		},
		
		// 保存
		async submit() {
			if (_this.$validator.isEmpty(_this.url)) {
				_this.$toast.fail({
					message: '请填写数据接口地址',
					duration: '800',
				})
				return;
			}
			if (_this.$validator.isEmpty(_this.password)) {
				_this.$toast.fail({
					message: '请填写账号登录密码',
					duration: '800',
				})
				return;
			}
			let _data = await _this.$request('/Ambulance/v1/PostApi',{
				method: 'POST',
				data: {
					Url: _this.url,
					Password: _this.password
				}
			});
			if (_data.Code == 0) {
				_this.$toast.success({
					message: '保存成功',
					duration: '1000',
					onClose(){
						_this.$router.go(-1);
					}
				})
			}
		}
  }
}
</script>

<style lang="less" scoped>
	.title{
		margin-left: 0;
	}
	.setting-index{
		height: auto;
		.setting-parameter{
			margin: 22px 0;
			.van-cell{
				padding: 8px 5px;
				width: 350px;
				border-radius: 2px;
				border: 1px solid #979797;
				font-size: 16px;
				line-height: 0;
			}
		}
		.submit-btn{
			width: 250px;
			height: 35px;
			margin: 30px auto;
			background: linear-gradient(90deg, #81CAE5 0%, #36A8CE 100%);
			border-radius: 5px;
		}
	}
</style>